<template>
  <div
    class="alarmRecordBox item-b"
    v-loading="showLoad"
    element-loading-text="拼命加载中"
  >
    <FormItem
      :formList="formList"
      :btn="btn"
      @search="onSearch"
      @add="onAdd"
      @reset="onReset"
      :btnObj="btnObj"
      :search="search"
    />
    <Table
      ref="table"
      :tableList="tableList"
      :tableData="tableData"
      @operation="onHandle"
      @currentNum="current"
      :total="total"
    />
    <el-drawer
      custom-class="drawer-class"
      :visible.sync="show"
      :show-close="false"
      :withHeader="false"
      :wrapperClosable="false"
    >
      <div class="drawer-box flex-col">
        <div class="title" v-if="isEdit">立即处置</div>
        <div class="title" v-else>查看详情</div>

        <div class="table auto">
          <el-form
            :model="postFormData"
            size="small"
            :rules="postFormDataRules"
            label-position="left"
            label-width="auto"
            ref="addForm"
            :hide-required-asterisk="true"
          >
            <el-form-item label="站点名称：" prop="siteName">
              <span class="blockSpan">{{ postFormData.siteName }}</span>
            </el-form-item>

            <el-form-item label="报警类型：" prop="type">
              <span class="blockSpan">{{
                postFormData.type | typeFilter
              }}</span>
            </el-form-item>
            <el-form-item label="报警时间：" prop="alarmTime">
              <span class="blockSpan">{{ postFormData.alarmTime }}</span>
            </el-form-item>
            <el-form-item label="负责人：" prop="staffName">
              <span class="blockSpan">{{ postFormData.staffName }}</span>
            </el-form-item>
            <el-form-item label="联系电话：" prop="phone">
              <span class="blockSpan">{{ postFormData.phone }}</span>
            </el-form-item>
            <el-form-item label="报警数值：" prop="reason">
              <span class="blockSpan">{{ postFormData.reason }}</span>
            </el-form-item>
            <el-form-item
              label="报警预值："
              prop="earlyWarningValue"
              v-if="stationproblem"
            >
              <span class="blockSpan">{{
                postFormData.earlyWarningValue
              }}</span>
            </el-form-item>

            <el-form-item label="地址：" prop="location">
              <span class="blockSpan">{{ postFormData.location }}</span>
            </el-form-item>
            <el-form-item
              label="问题类型："
              prop="Alarmreason"
              v-if="stationproblem"
            >
              <el-select
                v-model="baojingreason"
                v-if="isEdit"
                clearable
                placeholder="请选择"
                @change="changgeyuanyin"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <span class="blockSpan" v-else>{{ Questiontype }}</span>
            </el-form-item>

            <el-form-item label="处理详情：" prop="description">
              <template v-if="isEdit">
                <el-input
                  type="textarea"
                  :rows="6"
                  placeholder="请输入处理详情"
                  v-model="postFormData.description"
                  class="custom-submit-textarea"
                ></el-input>
              </template>
              <template v-else>
                {{ postFormData.description }}
              </template>
            </el-form-item>
          </el-form>
        </div>
        <div class="c-center btn">
          <el-button
            type="primary submit-primary"
            v-if="isEdit"
            @click="saveAdd"
            >保存</el-button
          >
          <el-button type="info submit-info" @click="cancelAdd">取消</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import axios from "@/request";
import moment from "moment";
export default {
  name: "AlarmRecord",
  data() {
    return {
      showLoad: false,
      formList: [
        {
          props: "type",
          el: "el-select",
          label: "报警类型",
          noClear: true,
          placeholder: "请选择",
          options: [
            {
              value: "0",
              label: "全部",
            },
            {
              value: "1",
              label: "氨气报警",
            },
            {
              value: "2",
              label: "硫化氢报警",
            },
            {
              value: "3",
              label: "转运站报警",
            },
          ],
        },

        // {
        //   props: "description",
        //   label: "处理描述",
        //   el: "el-input",
        //   placeholder: "请输入处理描述",
        // },

        {
          props: "Processingstatus",
          el: "el-select",
          label: "处理状态",
          noClear: true,
          placeholder: "请选择",
          width: "margin-left: 30px;",
          options: [
            {
              value: "0",
              label: "全部",
            },
            {
              value: "1",
              label: "未处理",
            },
            {
              value: "2",
              label: "已处理",
            },
          ],
        },
        {
          props: "toiletName",
          labelT: "站点名称",
          width: "margin-left: 30px;",
          el: "el-input",
          placeholder: "请输入站点名称",
        },
        {
          props: "time",
          el: "el-date-picker",
          label: "日期选择",
          placeholder: "请选择日期",
          width: "margin-left: 30px;",
        },
      ],
      btnObj: {
        search: "查询",
        reset: "重置",
      },
      btn: "search,reset",
      tableList: [
        {
          prop: "index",
          label: "序号",
          width: "60",
        },
        {
          prop: "siteName",
          label: "站点名称",
          width: "",
        },
        {
          prop: "alarmTime",
          label: "报警时间",
          width: "",
        },
        {
          prop: "type",
          label: "报警类型",
          width: "",
          formatter(val) {
            let str = "";
            switch (val.type) {
              case 1:
                str = "男氨气报警";
                break;
              case 2:
                str = "女氨气报警";
                break;
              case 3:
                str = "男硫化氢报警";
                break;
              case 4:
                str = "女硫化氢报警";
                break;
              case 5:
                str = "转运站报警";
                break;
              default:
                str = "暂无数据";
                break;
            }
            return str;
          },
        },
        {
          prop: "reason",
          label: "报警数值",
          width: "80px",
        },
        {
          prop:  "earlyWarningValue",
          label: "预警数值",
          width: "80px",
        },
        {
          prop: "status",
          label: "处理状态",
          width: "",
          color(val) {
            let color = "";
            switch (val) {
              case 1:
                color = "#FB4C4C";
                break;
              case 2:
                color = "#00da24";
                break;
            }
            return color;
          },
          formatter(val) {
            let str = "";
            switch (val.status) {
              case 1:
                str = "未处理";
                break;
              case 2:
                str = "已处理";
                break;
              default:
                str = "未处理";
                break;
            }
            return str;
          },
        },
        {
          prop: "location",
          label: "地址",
          width: "",
        },
        {
          prop: "deptName",
          label: "所属项目部",
          width: "",
        },
        // {
        //   prop: "description",
        //   label: "处理描述",
        //   width: "80px",
        // },
        {
          prop: "staffName",
          label: "负责人",
          width: "",
        },
        {
          prop: "phone",
          label: "联系电话",
          width: "",
        },

        {
          prop: "op",
          label: "操作",
          btn: [
            {
              type: "primary",
              name: "查看详情",
              prop: "detail",
              isHide(data) {
                return data.status == 2 ? false : true;
              },
            },
            {
              type: "danger",
              name: "立即处置",
              prop: "edit",
              isHide(data) {
                return data.status == 1 ? false : true;
              },
            },
          ],
        },
      ],
      tableData: [],
      page: 1,
      size: 10,
      total: 0,
      search: {
        type: "",
        time: {},
        toiletName: "",
      },
      show: false,
      isEdit: false,
      typeOptions: [
        {
          value: "1",
          label: "男氨气报警",
        },
        {
          value: "2",
          label: "女氨气报警",
        },
        {
          value: "3",
          label: "男硫化氢报警",
        },
        {
          value: "4",
          label: "女硫化氢报警",
        },
        {
          value: "5",
          label: "转运站报警",
        },
      ],
      nameId: "",
      statusId: "",
      typeID: "",
      postFormData: {},
      postFormDataRules: {},
      options: [
        {
          value: "1",
          label: "公厕维护",
        },
        {
          value: "2",
          label: "设备损坏",
        },
        {
          value: "3",
          label: "打扫不及时",
        },
        {
          value: "4",
          label: "其他",
        },
      ],
      baojingreason: "",
      alarmReason: "",
      Questiontype: "",
      stationproblem: false,
      thefullload: "",
      searchtwo: {},
      judge: false,
    };
  },
  
  filters: {
    typeFilter(val) {
      let str = "";
      switch (val) {
        case 1:
          str = "男氨气报警";
          break;
        case 2:
          str = "女氨气报警";
          break;
        case 3:
          str = "男硫化氢报警";
          break;
        case 4:
          str = "女硫化氢报警";
          break;
        case 5:
          str = "转运站报警";
          break;
        default:
          str = "暂无数据";
          break;
      }
      return str;
    },
  },
  created() {
    console.log(this.$route.query);
    this.judge = this.$route.query.judge;
    this.search.time = this.$route.query.time;
    this.search.toiletName = this.$route.query.params;
    this.search.type = this.$route.query.type
    let obj = {
      type: this.$route.query.type,
      startTime: this.$route.query.time
        ? moment(this.$route.query.time[0]).format("YYYY-MM-DD HH:mm:ss")
        : null,
      endTime: this.$route.query.time
        ? moment(this.$route.query.time[1]).format("YYYY-MM-DD HH:mm:ss")
        : null,
      toiletName: this.$route.query.params,
    };
    this.searchtwo = obj
    
    this.getData(this.page, this.size, obj);
  },
  mounted() {
    console.log(this.$route.params);
    this.nameId = this.$route.params.userId;
    this.statusId = this.$route.params.status;
    this.typeID = this.$route.params.typeID;
    // this.postFormData = Object.assign({}, this.$route.params.item);
    if (this.$route.query.userId && this.$route.query.status) {
      this.nameId = this.$route.params.userId;
      this.statusId = this.$route.params.status;
    }
    this.getAlarmdetails(this.nameId);
  },
  methods: {
    getData(page, size, val) {
      console.log(val);
      this.showLoad = true;
      axios
        .get(`/alarmRecord/page/${page}/${size}`, {
          params: {
            ...val,
          },
        })
        .then((res) => {
          console.log(res);
          this.tableData = res.list;
          this.total = res.total;
          // if (this.nameId) {
          //   let nameList = this.tableData.find((res) => {
          //     if (res.id == this.nameId) {
          //       return res;
          //     }
          //   });
          //   console.log(nameList);
          //   if (this.statusId == 1) {
          //     console.log("789");
          //     this.show = true;
          //     this.isEdit = true;
          //     this.postFormData = Object.assign({}, nameList);

          //     this.stationproblem = true;
          //   } else if (this.statusId == 2) {
          //     console.log("456");
          //     this.show = true;
          //     this.isEdit = true;
          //     this.postFormData = Object.assign({}, nameList);
          //   }

          //   this.nameId = "";
          //   this.statusId = "";
          // }
          this.showLoad = false;
        });
    },
    // 查询报警详情
    getAlarmdetails(id) {
      if (id) {
        axios.get(`/alarmRecord/findById/${id}`, {}).then((res) => {
        console.log(res);
        let locaList = res;
        if (this.nameId) {
          if (this.statusId == 1) {
            this.show = true;
            if (this.typeID ==1) {
              this.isEdit = true;
              this.postFormData = Object.assign({}, locaList);
              this.postFormData.description = ''
            } else {
              this.isEdit = false
              this.postFormData = Object.assign({}, locaList);
              
            }
            
            console.log(this.postFormData)
            
            this.stationproblem = true;
          } else if (this.statusId == 2) {
            this.show = true;
            if (this.typeID ==1) {
              this.isEdit = true;
              this.postFormData = Object.assign({}, locaList);
              this.postFormData.description = ''
            } else {
              this.isEdit = false
              this.postFormData = Object.assign({}, locaList);
              
            }
            
            
           
            // this.postFormData.description = ''
          }
          this.nameId = "";
          this.statusId = "";
        }
        this.showLoad = false;
        if (locaList.alarmReason == 1) {
          this.Questiontype = "公厕维护";
        } else if (locaList.alarmReason == 2) {
          this.Questiontype = "设备损坏";
        } else if (locaList.alarmReason == 3) {
          this.Questiontype = " 打扫不及时";
        } else if (locaList.alarmReason == 4) {
          this.Questiontype = "其他";
        }
      });

      }
      
    },

    // 选择报警原因
    changgeyuanyin(val) {
      this.alarmReason = val;
    },
    onSearch(val) {
      console.log(val);
      let obj = {
        status: val.Processingstatus,
        type: val.type,
        startTime: val.time
          ? moment(val.time[0]).format("YYYY-MM-DD HH:mm:ss")
          : null,
        endTime: val.time
          ? moment(val.time[1]).format("YYYY-MM-DD HH:mm:ss")
          : null,
        toiletName: val.toiletName,
        description: val.description,
      };
      this.search = obj;
      console.log(this.search);
      this.getData(this.page, this.size, obj);
    },
    onAdd(val) {},
    onReset(val) {
      console.log(val);
      this.search = {};
      console.log(this.search)
      this.judge = false
      this.search.type = 0,
      this.$refs.table.lastEmittedPage();
      this.getData(1, 10, this.search);
    },
    onHandle(val) {
      console.log(val)
      if (val.opVal === "edit") {
        this.edit(val.data);
      } else {
        this.detail(val.data);
      }
    },
    edit(data) {
      console.log(data);
      data.description = ''
      if (data.siteType == 1) {
        this.stationproblem = true;
      } else {
        this.stationproblem = false;
      }
      this.show = true;
      this.isEdit = true;
      this.postFormData = Object.assign({}, data);
      this.nameId = "";
      this.statusId = "";
    },
    detail(data) {
      this.show = true;
      this.isEdit = false;
      this.postFormData = Object.assign({}, data);
      console.log(this.postFormData);
      let reason = this.postFormData.alarmReason;
      if (data.siteType == 1) {
        this.stationproblem = true;
      } else {
        this.stationproblem = false;
      }
      if (reason == 1) {
        this.Questiontype = "公厕维护";
      } else if (reason == 2) {
        this.Questiontype = "设备损坏";
      } else if (reason == 3) {
        this.Questiontype = " 打扫不及时";
      } else if (reason == 4) {
        this.Questiontype = "其他";
      }
      this.nameId = "";
      this.statusId = "";
    },
    current(val,) {
      console.log(val);
      let page = val;
      console.log(this.searchtwo)
     
      if (this.judge) {
     
        this.getData(page, this.size, this.searchtwo);
      } else {
     
        this.getData(page, this.size, this.search)
      }
      
      
      
      
    },
    cancelAdd() {
      //Object.assign(this.$data, this.$options.data())
      this.$refs.addForm.resetFields();
      this.show = false;
      this.nameId = "";
      this.statusId = "";
      this.postFormData.description = "";
      //this.getData(this.page,this.size,this.search)
    },
    saveAdd() {
      // console.log(this.alarmReason);
      let { id, description } = this.postFormData;
      let dataObj = {
        id,
        description,
        disposeUserId: sessionStorage.getItem("id"),
        disposeUserName: sessionStorage.getItem("name"),
        alarmReason: this.alarmReason,
      };
      console.log(dataObj);
      axios
        .post("/alarmRecord/dispose", {
          ...dataObj,
        })
        .then((res) => {
          if (res == null) {
            this.$refs.addForm.resetFields();
            this.show = false;
            this.$message({
              message: "处置成功",
              type: "success",
            });
            
            this.getData(this.page, this.size, this.search);
          } else {
            this.$message({
              message: "处置失败",
              type: "error",
            });
          }
        });
      this.nameId = "";
      this.statusId = "";
      this.postFormData.description = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.alarmRecordBox {
  width: 100%;
  .drawer-box {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
    .blockSpan {
      font-weight: 500;
      color: #333333;
    }
    .title {
      padding: 20px;
      font-size: 1.2rem;
      font-weight: bold;
      text-align: center;
      border-bottom: solid 1px #dadada;
    }
    .table {
      padding: 20px;
      overflow: auto;
    }
    .btn {
      padding: 30px 0;
    }
  }
}
</style>
